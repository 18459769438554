import React from "react";
import HeroSection from "../../HeroSection";
import { homeObjThree,homeObjFive } from "./Data";

export default function FeaturedWorks() {
  return (
    <>
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFive} />
    </>
  );
}
