import React from "react";
import "./Footer.css";
// import { Button } from "../../Button";
import { Link } from "react-router-dom";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

export default function Footer() {
  return (
    <div className="footer-container">
      <section className="footer-subscription">
        {/* <p className="footer-subscription-heading">
          Keep me up to date with content, updates, and offers from coderich
        </p> */}
        {/* <p className="footer-subscription-text">
          You can unsubscribe at any time.
        </p> */}
        {/* <div className="input-areas">
          <form>
            <input
              className="footer-input"
              name="email"
              type="email"
              placeholder="Your Email"
            />
            <Button buttonStyle="btn--outline">Submit</Button>
          </form>
        </div> */}
      </section>
      <div className="footer-links">
        <div className="footer-link-wrapper">
          <div className="footer-link-items">
            <h2>About Us</h2>
            <Link to="/services">Services</Link>
            <Link to="/featured_works">Featured Works</Link>
            <Link to="/">Testimonials</Link>
            <Link to="/">Careers</Link>
            <Link to="/">Investors</Link>
          </div>
          <div className="footer-link-items">
            <h2>Contact Us</h2>
            <Link to="/">coderichsoftwares@gmail.com</Link>
            <Link to="/">+233 549118813</Link>
            {/* <Link to="/">+233 202166365</Link> */}
            {/* <Link to="/">Sponsorships</Link> */}
          </div>
        </div>
        <div className="footer-link-wrapper">
          {/* <div className="footer-link-items">
            <h2>Videos</h2>
            <Link to="/">Submit Video</Link>
            <Link to="/">Ambassadors</Link>
            <Link to="/">Agency</Link>
            <Link to="/">Influencer</Link>
          </div> */}
          <div className="footer-link-items">
            {/* <h2>Social Media</h2>
            <Link to={"//www.instagram.com/coderichsoftware/"} target="_blank">
              Instagram
            </Link>
            <Link to={"//web.facebook.com/coderichsoftware"} target="_blank">
              Facebook
            </Link> */}
            {/* <Link to="/">Youtube</Link> */}
            {/* <Link to="/">Twitter</Link> */}
          </div>
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <div className="footer-logo">
            <Link to="/" className="social-logo">
              <img src="./coderich.png" alt="coderich" height={40} />
            </Link>
          </div>
          <small className="website-rights">CODERICH © 2024</small>
          <div className="social-icons">
            <Link
              className="social-icon-link"
              to={"//web.facebook.com/coderichsoftware"}
              target="_blank"
              aria-label="Facebook"
            >
              <FaFacebook />
            </Link>

            <Link
              className="social-icon-link"
              to={"//www.instagram.com/coderichsoftware/"}
              target="_blank"
              aria-label="Instagram"
            >
              <FaInstagram />
            </Link>

            <Link className="social-icon-link" to="/" aria-label="Youtube">
              <FaYoutube />
            </Link>
            <Link className="social-icon-link" to="/" aria-label="Twitter">
              <FaTwitter />
            </Link>
            <Link className="social-icon-link" to="/" aria-label="LinkedIn">
              <FaLinkedin />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}
