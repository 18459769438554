import React, { useState } from "react";
import { Link } from "react-router-dom";
// import { MdFingerprint } from "react-icons/md";
import { FaBars, FaTimes } from "react-icons/fa";
// import { Button } from "./Button";useEffect
import "./Navbar.css";
import { IconContext } from "react-icons/lib";

export default function Navbar() {
  //setstate
  const [click, setClick] = useState(false);
  // const [button, setButton] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  // const showButton = () => {
  //   if (window.innerWidth <= 960) {
  //     setButton(false);
  //   } else {
  //     setButton(true);
  //   }
  // };

  // useEffect(() => {
  //   showButton();
  // }, []);

  // window.addEventListener("resize", showButton);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <div className="navbar">
          <div className="navbar-container container">
            <Link to="/" className="navbar-logo">
              {" "}
              <img src="./coderich.png" alt="coderich" height={40} />
            </Link>
            <div className="menu-icon" onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <Link to="/" className="nav-links" onClick={closeMobileMenu}>
                  Home
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/services"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Services
                </Link>
              </li>

              <li className="nav-item">
                <Link
                  to="/featured_works"
                  className="nav-links"
                  onClick={closeMobileMenu}
                >
                  Featured Works
                </Link>
              </li>

              {/* <li className="nav-btn">
                {button ? (
                  <Link to="/letstalk" className="btn-link">
                    <Button buttonStyle="btn--outline">Let's Talk</Button>
                  </Link>
                ) : (
                  <Link
                    to="/letstalk"
                    className="btn-link"
                    onClick={closeMobileMenu}
                  >
                    <Button buttonStyle="btn--outline" buttonSize="btn--mobile">
                      Let's Talk
                    </Button>
                  </Link>
                )}
              </li> */}
            </ul>
          </div>
        </div>
      </IconContext.Provider>
    </>
  );
}
