import React from "react";
import HeroSection from "../../HeroSection";
import { homeObjOne, homeObjTwo, homeObjThree,homeObjFive } from "./Data";
import Pricing from "../../Pricing";

export default function Home() {
  return (
    <>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjThree} />
      <HeroSection {...homeObjFive} />
      <HeroSection {...homeObjTwo} />
      {<Pricing />}
    </>
  );
}
