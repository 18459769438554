import React from "react";
import Icon1 from "../images/android.jpeg";
import Icon2 from "../images/web.jpeg";
import Icon3 from "../images/api.jpeg";
import Icon4 from "../images/singlepage.jpeg";
import Icon5 from "../images/ussd.jpeg";
import Icon6 from "../images/edtech.jpeg";

import {
  PricingContainer,
  PricingH1,
  PricingWrapper,
  PricingCard,
  PricingIcon,
  PricingH2,
  PricingP,
} from "./PricingElement";

export default function Pricing() {
  return (
    <PricingContainer id="pricing">
      <PricingH1>Professional Services</PricingH1>
      <PricingP>We offer the best services to our clients</PricingP>
      <PricingWrapper>
        <PricingCard>
          <PricingIcon src={Icon1} />
          <PricingH2>Android & IOS Mobile Development</PricingH2>
        </PricingCard>

        <PricingCard>
          <PricingIcon src={Icon2} />
          <PricingH2>Web Development</PricingH2>
        </PricingCard>

        <PricingCard>
          <PricingIcon src={Icon3} />
          <PricingH2>API Design & Development</PricingH2>
        </PricingCard>

        <PricingCard>
          <PricingIcon src={Icon4} />
          <PricingH2>Single Page Applications</PricingH2>
        </PricingCard>

        <PricingCard>
          <PricingIcon src={Icon5} />
          <PricingH2>USSD Applications</PricingH2>
        </PricingCard>

        <PricingCard>
          <PricingIcon src={Icon6} />
          <PricingH2>EdTech</PricingH2>
        </PricingCard>
      </PricingWrapper>
    </PricingContainer>
  );
}
