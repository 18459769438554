import "./App.css";
import Home from "./components/pages/HomePage/Home";
import Services from "./components/pages/Services/Services";
import FeaturedWorks from "./components/pages/FeaturedWorks/FeaturedWorks";
import LetsTalk from "./components/pages/LetsTalk/LetsTalk";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/pages/Footer/Footer";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/services" component={Services} />
        <Route path="/featured_works" component={FeaturedWorks} />
        <Route path="/letstalk" component={LetsTalk} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
